(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbOrder.service:OrderService
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .service('OrderService', OrderService);

  function OrderService(Cart, _) {
    var successData
      , activeStateIndex
      , inProgress = false
      , hasNewCardOrder = false
      , hasTopUpOrder = false;

    this.getSuccessData = function getSuccessData() {
      return successData;
    };

    this.setSuccessData = function setSuccessData(data) {
      successData = data;
    };

    this.getActiveStateIndex = function getActiveStateIndex() {
      return activeStateIndex;
    };

    this.setActiveStateIndex = function setActiveStateIndex(newStateIndex) {
      activeStateIndex = newStateIndex;
    };

    this.getInProgress = function getInProgress() {
      return inProgress;
    };

    this.setInProgress = function setInProgress() {
      inProgress = true;
    };

    this.clearInProgress = function clearInProgress() {
      inProgress = false;
    };

    this.clearStorageItems = function clearStorageItems() {
      localStorage.removeItem('multiWalletItems');
    };

    this.updateCart = function updateCart() {
      return Cart
        .query()
        .$promise
        .then(function (response) {
          hasNewCardOrder = !_.isNull(response[0].order);
          hasTopUpOrder = !_.isNull(response[1].order);
        });
    };

    this.cartHasNewCardOrder = function cartHasNewCardOrder() {
      return hasNewCardOrder;
    };

    this.cartHasTopUpOrder = function cartHasTopUpOrder() {
      return hasTopUpOrder;
    };

    this.updateCart();
  }
}());
